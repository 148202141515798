// Translated
// Migrated
<template>
  <div class="w-full mx-auto px-4 booking-wrap">
    <div class="flex flex-wrap -mx-4">
      <ScreenWidthProvider
        v-slot="{ isWider }"
        class="w-12/12 lg:w-5/12 p-0"
      >
        <ResponsiveImage
          v-if="isWider('md')"
          class="article-block-img flex flex-col size-full"
          :image="image"
          alt="Calendar"
          cover
        />
      </ScreenWidthProvider>
      <div class="w-12/12 lg:w-7/12 booking-content px-4">
        <h4
          class="calendar-coming-departures-title mb-0 xl:mb-2 mx-2 md:mx-0"
          data-i18n="upcomingDepartures"
        >
          {{ $t('upcomingDepartures') }}
        </h4>
        <div class="booking-content-items">
          <CalendarTable
            class="mb-4"
            :trips="trips"
          />
          <nuxt-link
            class="w-full text-center md:text-left block"
            :to="localeURLs.calendar"
            data-i18n="seeCalendarLink"
          >
            {{ $t('seeCalendarLink') }} >
          </nuxt-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'pinia'

export default defineNuxtComponent({
  setup () {
    const { urls: localeURLs } = useLocale()

    return {
      localeURLs,
    }
  },

  data () {
    return {
      image: 'v1688126803/general/booking-bg-2',
      sizeList: [{
        maxWidth: null,
        size: 1280 * 1.5,
      }, {
        maxWidth: 992,
        size: 992 * 2,
      }, {
        maxWidth: 600,
        size: 800 * 1.5,
      }],
    }
  },

  computed: {
    ...mapState(useRootStore, {
      trips: 'startCalendar',
    }),
  },
})
</script>

<style lang="scss">
@import "@/assets/scss/modules/main-page/_booking";
.booking-wrap {
  .calendar-coming-departures-title {
    font-size: 1.25em;
  }

  .article-block-img {
    @media (max-width: theme('screens.lg')) {
      height: 250px !important;
    }
  }
}
</style>

// Migrated
<template>
  <div>
    <div class="booking-table">
      <div class="booking-table__row h6 mb-0 font-medium justify-content-bottom flex flex-wrap">
        <div
          class="booking-table__col md:w-2/12 lg:w-3/12 md:pl-0 hidden md:flex mt-auto py-4 pr-4"
          data-i18n="date"
        >
          {{ $t('date') }}
        </div>
        <div
          class="booking-table__col w-6/12 md:w-5/12 lg:w-4/12 mt-auto py-4 px-4"
          data-i18n="destination"
        >
          {{ $t('destination') }}
        </div>
        <div
          class="booking-table__col w-3/12 md:w-3/12 mt-auto py-4 px-4"
          data-i18n="price"
        >
          {{ $t('price') }}
        </div>
        <div class="booking-table__col w-3/12 md:w-2/12 lg:w-2/12 justify-center pr-0 mt-auto py-4 text-right sm:text-center pl-4">
          <div
            class="md:text-nowrap text-center"
            data-i18n="seatsLeft"
          >
            {{ $t('seatsLeft') }}
          </div>
        </div>
      </div>
      <nuxt-link
        v-for="(trip, index) in filteredTrips"
        :key="index"
        class="booking-table__row flex flex-wrap"
        :to="trip.triptypeurl"
      >
        <div class="booking-table__col md:pl-0 w-6/12 md:w-2/12 lg:w-3/12 text-break pr-0 pl-4">
          <span>
            {{ localeDateFormat(trip.date) }}
          </span>
        </div>
        <div class="booking-table__col w-12/12 md:w-5/12 lg:w-4/12 px-4">
          <div class="h6 font-medium text-break booking-content__title">
            {{ trip.tripname }}
          </div>
        </div>
        <div class="booking-table__col w-3/12 md:w-3/12 px-4">
          <span
            class="booking-content__price"
            :class="{ 'text-discounted': trip.discounted }"
          >{{ $n(trip.price) }}</span>
        </div>
        <div class="booking-table__col justify-center pr-0 w-3/12 md:w-2/12 lg:w-2/12 pl-4">
          <span
            class="booking-content__plats text-right sm:text-center"
            data-i18n="tripFull"
          >{{ seatsLeft(trip.seats_left) }}</span>
        </div>
      </nuxt-link>
    </div>
  </div>
</template>

<script>
import calendarMixin from '@layers/web/mixins/calendar.mixin'

export default defineNuxtComponent({
  setup () {
    const { localeDateFormat } = useDate()

    return {
      localeDateFormat,
    }
  },

  mixins: [calendarMixin],

  props: {
    trips: {
      type: Array,
      required: true,
    },
  },

  computed: {
    filteredTrips () {
      return this.trips.slice(0, 11)
    },
  },
})
</script>